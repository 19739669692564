import { render, staticRenderFns } from "./ReturnContent.vue?vue&type=template&id=45afe8f0&scoped=true"
import script from "./ReturnContent.vue?vue&type=script&lang=js"
export * from "./ReturnContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45afe8f0",
  null
  
)

export default component.exports